import React from "react";
import Navigationbar from "../Components/Navigationbar";

export default function Membership() {

  return(
  <>
    <Navigationbar highlighted={'membership'}/>
  </>
  );
}
