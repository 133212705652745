const pages = [
  {
    text: 'Home',
    link: 'home'
  },
  {
    text: 'Events',
    link: 'events'
  },
  {
    text: 'About',
    link: 'about'
  },
  {
    text: 'Join Us!',
    link: 'joinus'
  },
];

export default pages;