import React from "react";
import Navigationbar from "../Components/Navigationbar";

export default function Contact() {

  return(
  <>
    <Navigationbar highlighted={'contact'}/>
  </>
  );
}
